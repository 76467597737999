<template>
  <div>
    <div class="title text-uppercase secondaryColor--text py-4">Last paid invoices</div>
  
    <v-data-table
      class="defaultBg"
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      loader-height="2"
      mobile-breakpoint="0"
      hide-default-footer
      disable-pagination
    >
      <template #item.buyer="{ item }">
        <v-tooltip
          color="mainBg"
          open-delay="100"
          class="rounded"
          bottom
        >
          <template #activator="{ on }">
            <span class="text-no-wrap" v-on="on">{{ item.buyer.name }}</span>
          </template>
          <span>{{ item.buyer.email }}</span>
        </v-tooltip>
      </template>
      
      <template #item.amount="{ item }">
        {{ currency(item.amount) }}
      </template>
  
      <template #item.amountPaid="{ item }">
        {{ currency(item['amount_paid']) }}
      </template>
  
      <template #item.createdDate="{ item }">
        <span class="text-no-wrap">{{ getFormatDate(item['created_at']) }}</span>
      </template>
  
      <template #item.paymentDate="{ item }">
        <span class="text-no-wrap">
          {{ item['payment_date'] ? getFormatDate(item['payment_date']) : '-//-' }}
        </span>
      </template>
  
      <template #item.payment="{ item }">
        {{ item['payment_system'] ? item['payment_system'].name : '-//-' }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import currency from '@/mixins/currency'

import { format } from 'date-fns'

export default {
  name: 'PayedInvoices',
  inheritAttrs: false,
  mixins: [ currency ],
  data: () => ({
    options: {},
    headers: [
      { text: 'Id', value: 'id', sortId: 'byId' },
      { text: 'Buyer', value: 'buyer', sortId: 'byBuyer' },
      { text: 'Amount', value: 'amount', sortId: 'byAmount' },
      { text: 'Amount Paid', value: 'amountPaid', sortId: 'byAmountPaid' },
      { text: 'Status', value: 'status.name', sortId: 'byStatus' },
      { text: 'Creation Date', value: 'createdDate', sortId: 'byCreationDate' },
      { text: 'Payment Date', value: 'paymentDate', sortId: 'byPaymentDate' },
      { text: 'Pay method', value: 'payment', sortId: 'byPayMethod', width: 200 },
    ],
  }),
  computed: {
    ...mapState({
      loading: state => state.dashboard.loading,
      items: state => state.dashboard.payedData,
    })
  },
  methods: {
    getFormatDate(date) {
      return format(new Date(date), 'Y-MM-dd HH:mm:ss')
    }
  }
}
</script>
